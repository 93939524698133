<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                国联证券联合非凸科技举办量化闭门会议
              </p>
              <div class="fst-italic mb-2">2023年9月22日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >国联证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/57/01-现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    9月19日，国联证券大方向联合非凸科技在上海举办了“量化投资管理人闭门交流会”，与资方管理人就机构业务、算法交易、量化策略等方面展开了深度交流，分享了各自见解与经验。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/57/02-黄隽.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/57/03-黄冰熠.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    作为综合类券商，国联证券已形成包括财富管理、投资银行、资产管理、研究与机构销售、固定收益、股权衍生品与私募股权投资业务等在内较为完善的业务体系。国联证券“大方向”投顾，打造了“5+N”策略体系，开创了To B业务模式，满足客户在不同场景下的资产管理需求。会上，国联证券私募业务部负责人黄隽从“资金支持、系统支持、行政服务、基金评价服务、交易支持服务、投研服务”等方面，详细介绍了私募服务体系。随后，国联证券上海分公司副总经理黄冰熠对资方与管理人服务体系进行了细致讲解，旨在为优质私募与资金方提供更为高效、专业、精准的对接匹配机会。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/57/04-周升.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    非凸科技机构业务经理周升从算法交易、业务合作、算法评价等方面，分享了非凸算法服务如何赋能投资交易。非凸科技积极响应行业发展趋势，持续技术创新，以“全市场、全场景、全业务、全链路”的运营布局，为券商、私募等大型金融机构提供一站式智能交易领域服务解决方案。在算法策略上，非凸科技具备自研的实力和潜力，可以根据客户需求打造多品类、高质量、高绩效的算法产品，包括拆单算法、T0算法等。非凸科技将进一步加强对一站式算法服务平台的调研分析，与券商机构协力为量化行业带来更多优质的产品与服务。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/57/05-李烨.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    艾悉资产致力于用量化投资的技术策略，为投资者创造长期稳健的绝对收益。艾悉资产量化研究总监李烨分享了“中证500指数增强、中证1000指数增强、市场中性”等多种股票量化策略，其算法交易模块采用了非凸算法、自主研发交易算法等，以及自主研发风控系统，实时监控交易状态，确保符合预期。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/57/06-杨领.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    竹润投资专注于“固收+”多元复合策略，长期聚焦债券市场，致力于为客户提供多层次、全方位的资产管理服务。竹润投资合伙人杨领详细介绍了“指增及固收策略”的产品情况及净值表现，并从“数据清洗、因子挖掘、模型预测、组合优化、风险控制、交易执行”等纬度，细致分析了股票量化投资的整个流程。
                  </p>
                  <p>
                    未来，非凸科技与国联证券将持续深化合作，携手面对行业的发展与变化，共同引领金融科技的创新与应用，为量化行业高质量发展赋能。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News57",
};
</script>

<style></style>
